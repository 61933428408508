import axios from 'axios';
import emailValidator from 'email-validator';

const ERROR_MESSAGE_REQUIRED = 'ERROR_MESSAGE_REQUIRED';
const ERROR_MESSAGE_EMAIL = 'ERROR_MESSAGE_EMAIL';
const ERROR_MESSAGE_PRIVACY = 'ERROR_MESSAGE_PRIVACY';
let activeErrorMessage = '';

const errorMessages = {
  ERROR_MESSAGE_REQUIRED: 'Pflichtfelder sind mit * gekennzeichnet.',
  ERROR_MESSAGE_EMAIL: 'Invalid email address.',
  ERROR_MESSAGE_PRIVACY: 'Sie müssen unsere Datenschutzbestimmungen akzeptieren.'
};

const requiredElements = [
  'name', 'lastName', 'company', 'position', 'phone'
];

const showValidationMessage = message => {
  const validationErrorMessageElement = document.getElementById('validationErrorMessage');
  validationErrorMessageElement.innerText = message;
  validationErrorMessageElement.setAttribute('style', 'opacity: 1; height: 1.25rem; margin-top: 0.5rem');
}

const hideValidationMessage = () => {
  const validationErrorMessageElement = document.getElementById('validationErrorMessage');
  validationErrorMessageElement.innerText = '';
  validationErrorMessageElement.setAttribute('style', 'opacity: 0; height: 0;');
}

const showElement = elementId => {
  const allElements = [
    document.getElementById('loadingAnimation'),
    document.getElementById('successMessage'),
    document.getElementById('errorMessage')
  ];
  const contactFormContainer = document.getElementById('contactFormContainer');
  const elementToShow = document.getElementById(elementId);

  allElements.forEach(element => element.setAttribute('style', 'opacity: 0; pointer-events: none'));
  contactFormContainer.setAttribute('style', 'opacity: 0; pointer-events: none;');
  elementToShow.setAttribute('style', 'opacity: 1; pointer-events: auto;');
}

const addLinkedIn1pixImage = () => {
  const div = document.createElement('div');
  div.innerHTML = `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2177730&conversionId=2397570&fmt=gif" />`;
  document.getElementById('successMessage').appendChild(div);
}

// VALIDATION FUNCTIONS
const getEmptyRequiredElements = () => {
  return requiredElements
    .filter(element => document.getElementById(element).value === '');
}

const validateEmail = () => {
  const emailElement = document.getElementById('email');
  return emailElement.value === '' || emailValidator.validate(emailElement.value);
}

const validateForm = () => {
  const invalidFields = getEmptyRequiredElements();
  if (invalidFields.length > 0) {
    showValidationMessage(errorMessages.ERROR_MESSAGE_REQUIRED);
    invalidFields.forEach(invalidElement => document.getElementById(invalidElement).classList.add("error"));
    activeErrorMessage = ERROR_MESSAGE_REQUIRED;
    return false;
  }

  if (!validateEmail()) {
    showValidationMessage(errorMessages.ERROR_MESSAGE_EMAIL);
    activeErrorMessage = ERROR_MESSAGE_EMAIL;
    return false;
  }

  if (!document.getElementById('privacyPolicy').checked) {
    showValidationMessage(errorMessages.ERROR_MESSAGE_PRIVACY);
    activeErrorMessage = ERROR_MESSAGE_PRIVACY;
    return false;
  }

  return true;
}

// FORM SUBMIT HANDLER
const formSubmitHandler = event => {
  event.preventDefault();
  if (!validateForm()) return ;

  showElement('loadingAnimation');

  const contactForm = document.getElementById('contactForm');

  const form = new FormData(contactForm);

  setTimeout(() => {
    axios(
      {
        method: 'post',
        url: '/submit.php',
        data: form,
        headers: {'Content-Type': 'multipart/form-data' }
      }
    )
      .then(result => {
        if (result.status === 200) {
          showElement('successMessage');
          addLinkedIn1pixImage();
        } else {
          showElement('errorMessage');
        }
      })
      .catch(() => {
        showElement('errorMessage');
      });
  }, 500);
}

//REQUIRED TEXT-BASED ELEMENTS - INPUT HANDLER
const inputHandler = event => {
  event.target.classList.remove('error');
  const invalidFields = getEmptyRequiredElements();
  if (invalidFields.length === 0) {
    hideValidationMessage();
  }
}

// PRIVACY-POLICY CHECKBOX CHANGE HANDLER
const checkboxChangeHandler = () => {
  if (activeErrorMessage === ERROR_MESSAGE_PRIVACY) {
    console.info('Remove error style from checkbox');
    hideValidationMessage();
  }
}

window.addEventListener('load', () => {
  const contactForm = document.getElementById('contactForm');
  const { elements } = contactForm;

  contactForm.addEventListener('submit', formSubmitHandler);

  //REQUIRED TEXT-BASED INPUTS
  requiredElements.forEach(element => {
    elements[element].addEventListener('input', inputHandler);
  });

  // PRIVACY CHECKBOX
  const privacyPolicyCheckbox = elements['privacyPolicy'];
  privacyPolicyCheckbox.addEventListener('change', checkboxChangeHandler);

  // BACK BUTTON
  document.getElementById('backButton')
    .addEventListener('click', () => showElement('contactFormContainer'));
});
